<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row flex-nowrap align-items-center">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="schoolData.config.logo_url"
                />
              </span>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <!-- <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              /> -->
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>

        <v-select
          ref="programSelect"
          v-model="defaultPragram"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="programOptions"
          :clearable="false"
          :reduce="option => option.id"
          :placeholder="'Select Program'"
          @input="clearDataFromOldProgram"
        >
          <template #list-footer>
            <li class="mx-1 py-1 border-top border-top-secondary">
              <b-button
                :disabled="!canCreateProgram"
                class="w-100"
                variant="outline-primary"
                @click="createProgram"
              >
                <span class="ml-25 align-middle">Create Program</span>
              </b-button>
            </li>
          </template>
        </v-select>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
      <vertical-nav-menu-school-switcher />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink, BImg, BButton,
} from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import vSelect from 'vue-select'
import { setUserData } from '@core/mixins/setUserData'
import { can } from '@core/libs/acl/utils'
import store from '@/store'
import campAdminPanel from '@/navigation/vertical/admin'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import VerticalNavMenuSchoolSwitcher from './components/vertical-nav-menu-school-switcher/VerticalNavMenuSchoolSwitcher.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    VerticalNavMenuSchoolSwitcher,
    BLink,
    BImg,
    BButton,
    vSelect,
  },
  mixins: [setUserData],
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
      defaultPragram,
      programOptions,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    const canCreateProgram = computed(() => can('create', permissionSubjects.Program))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    const navMenuItems = campAdminPanel

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
      defaultPragram,
      programOptions,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      canCreateProgram,
    }
  },
  async created() {
    this.setUserData()
    await this.$store.dispatch('verticalMenu/fetchProgramOptions')
    this.setDefaultProgramIfNotSelectedOrFromAnotherInstanse()
  },
  methods: {
    setDefaultProgramIfNotSelectedOrFromAnotherInstanse() {
      if (!this.defaultPragram || !this.programOptions.some(option => option.id === this.defaultPragram)) {
        const defaultProgram = this.programOptions[0]?.id || ''
        this.$store.commit('verticalMenu/UPDATE_DEFAULT_PROGRAM', defaultProgram)
        this.$router.push({ query: { program_id: defaultProgram } })
      }
    },
    createProgram() {
      this.$router.push({ name: 'admin-program-add' })
      const { searchEl } = this.$refs.programSelect
      if (searchEl) {
        searchEl.blur()
      }
    },
    moveToProgramSettingsTab(programId, tabIndex) {
      this.$router.push({ name: 'admin-program-edit', params: { id: programId }, query: { tab_index: tabIndex } })
    },
    clearDataFromOldProgram(programId) {
      if (this.$router.currentRoute.name === 'admin-program-edit') {
        const tabIndex = this.$router.currentRoute.query.tab_index || 0
        this.moveToProgramSettingsTab(programId, tabIndex)
      }
      store.commit('app-applications/SET_MARKED_ELEMENTS', [])
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
